import store from 'redux/store'
import { getProfile } from 'redux/selectors'
import { getCognitoUser } from 'redux/selectors'

// custom dimensions
// crmId - constituent lookup id
// cognitoId - cognito user's unique sub id
// loginMethod - 'email', 'phone', 'facebook' or 'google'
// entryPath - 'signin' or 'registration'

// other data layer variables
// registrationStepName - 'entry', 'verify' (email registration only) or 'complete'

// INTERNAL
// sets custom dimensions into object for passing along to data layer
const withDimensions = ( data ) => {
  console.log('withDimensions', {data})
  const profile = getProfile(store.getState())

  const loginMethod = localStorage.getItem('loginMethod') || ''
  const entryPath = localStorage.getItem('entryPath') || ''
  const crmId = profile ? profile.constituentLookupId : null

  let merged = {
    ...data,
    loginMethod,
    entryPath,
    crmId
  }

  // we need a unique identifier per account
  try {
    const user = getCognitoUser(store.getState())
    merged.cognitoId = user.attributes.sub
    merged.isInternalUser = testForInternalUser(user.attributes.email || user.attributes.phone_number)
  } catch (e) {}

  return merged
}

const testForInternalUser = (cognitoId) => {
  // internal users, or test users used by e2e tests
  return (/@test.com/.test(cognitoId)
          || /@support.ucla.edu/.test(cognitoId)
          || /\+14242726169/.test(cognitoId)
          || /crm.user@gmail.com/.test(cognitoId)
          || /dp.no.crm@gmail.com/.test(cognitoId)
          || /dp.system.error.simulation@gmail.com/.test(cognitoId)
          || /@support.ucla.edu/.test(cognitoId)
          || /@synergizethis.com/.test(cognitoId))
}

// do not interact with data layer if user is logged in as an admin or test user
const pushDataLayer = ( data, isInit ) => {
  // this is comparing a string to a boolean
  // if (localStorage.getItem('isDpAdmin') !== true) {
  try {
    const dataWithDimensions = !isInit ? withDimensions(data) : data
    if (!dataWithDimensions.isInternalUser) {
      console.log('pushing to dataLayer!', data)
      window.dataLayer.push( dataWithDimensions )
    }
  }
  catch (e) {
    console.log('pushDataLayer failed', e)
  }
  // }
  return;
}

// LOGIN & REGISTRATION
// we're using virtual pageviews for destination goal tracking
// using local storage to track entry paths across OAuth logins (nothing secret)

// use custom events on button click to start paths
// email or phone registration
// const registrationSteps = [
//   'entry',
//   'verify',
//   'complete'
// ]

// third party login registrations
// const socialRegistrationPath = [
//   'entry',
//   'complete'
// ]

// fire this when user completes login or registration
const analyticsClearStorage = () => {
  localStorage.removeItem('loginMethod')
  localStorage.removeItem('entryPath')
}

// ROUTING / HISTORY
// set crmId if available on page load
export const analyticsPageLoad = () => {
  console.log('analyticsPageLoad')
  const profile = getProfile(store.getState())
  const cognitoUser = getCognitoUser(store.getState())
  const data = profile?.constituentLookupId? {
    crmId: profile.constituentLookupId
  } : {}

  if (cognitoUser?.attributes?.sub) {
    data.cognitoId = cognitoUser.attributes.sub
  }

  pushDataLayer(data)
}

// side effects but we need this to do everything consistently
export const analyticsInitStorage = ({ loginMethod, entryPath }) => {
  console.log('analyticsInitStorage', {loginMethod,entryPath})
  loginMethod = loginMethod || ''
  entryPath = entryPath || ''

  localStorage.setItem('loginMethod', loginMethod)
  localStorage.setItem('entryPath', entryPath)

  pushDataLayer({
    loginMethod,
    entryPath
  }, true)
}

export const analyticsLogin = () => {
  console.log('analyticsLogin')
  const entryPath = localStorage.getItem('entryPath')
  if (entryPath === 'registration') {
    analyticsRegistrationStep('complete')
  }
  else {
    pushDataLayer({
      'event':'loginCRM'
    })
  }
}

export const analyticsFailedLogin = (errorMessage) => {
  console.log('analyticsFailedLogin', {errorMessage})
  pushDataLayer({
    'event':'loginFailed',
    errorMessage
  })

  analyticsClearStorage()
}

export const analyticsLoginConsolidate = () => {
  console.log('analyticsLoginConsolidate')
  pushDataLayer({
    'event':'loginConsolidate'
  })

  analyticsClearStorage()
}

export const analyticsLoginNoHistory = () => {
  console.log('analyticsLoginNoHistory')

  const entryPath = localStorage.getItem('entryPath')
  if (entryPath === 'registration') {
    analyticsRegistrationStep('complete')
  }
  else {
    pushDataLayer({
      'event':'loginNoHistory'
    })
    analyticsClearStorage()
  }
}

// one tag to fire all registration step events
export const analyticsRegistrationStep = (registrationStepName) => {
  console.log('analyticsRegistrationStep', {registrationStepName})
  pushDataLayer({
    'event':'registrationStep',
    registrationStepName,
  })
}

export const analyticsUpdateMyInfo = () => {
  console.log('analyticsUpdateMyInfo')
  pushDataLayer({
    'event':'accountUpdateInfo'
  })
}

// GIVING HISTORY
export const analyticsGiveAgain = ( revId ) => {
  console.log('analyticsGiveAgain',{revId})
  pushDataLayer({
    'event':'giveAgainLink',
    revId
  })
}

export const analyticsPayNow = ( pledgeRevId ) => {
  console.log('analyticsPayNow', {pledgeRevId})
  pushDataLayer({
    'event':'payNowLink',
    pledgeRevId
  })
}

export const analyticsYearReceipt = ( printYear ) => {
  console.log('analyticsYearReceipt', {printYear})
  pushDataLayer({
    'event':'printYearReceipt',
    printYear
  })
}

export const analyticsSingleReceipt = ( revId, receiptYear ) => {
  console.log('analyticsSingleReceipt', {revId, receiptYear})
  pushDataLayer({
    'event':'printSingleReceipt',
    revId,
    receiptYear,
  })
}

// CONTACT FORM
export const analyticsContactUs = () => {
  console.log('analyticsContactUs')
  pushDataLayer({
    'event':'contactSubmit'
  })
}
